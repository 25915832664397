<template>
  <article class="green-city">
    <Hero
      heading="Electrify America's Green City"
      subheading="Accelerating freight and transit electrification in
          Long Beach and the Wilmington neighborhood of Los Angeles"
    >
      <template #image>
        <img src="@/assets/images/Green-City/hero@2x.png" alt="View of green city shipping containers" />
      </template>
      <h2>The next frontier of electrification</h2>
      <p>
        Electrify America’s Green City program is investing $25 million in Long Beach and the Wilmington neighborhood of
        Los Angeles to install Hyper-Fast EV charging infrastructure. This investment will support the electrification
        of public transit and freight trucks serving the community.
      </p>
    </Hero>
    <div class="l-one__col l-one__col--wide l-one__col--no-side-padding">
      <Card heading="Investing in the future" headlineLevel="h2" isReverse>
        <template #image>
          <img
            loading="lazy"
            src="@/assets/images/Green-City/future-one@2x.png"
            alt="Loading dock with shipping containers on ships"
          />
        </template>
        <p class="title">Creating a Green City</p>
        <p>
          The Green City program focuses on projects with strong potential to have lasting positive impacts on the local
          community. Areas identified for improvement include transit buses and medium- and heavy-duty freight truck
          fleets. Our investments will take the form of charging hardware, utility infrastructure, and associated
          project management costs – such as site surveying & design, construction, and commissioning.
        </p>
      </Card>
      <Card :isReverse="isPhone">
        <template #image>
          <img
            loading="lazy"
            src="@/assets/images/Green-City/future-two@2x.png"
            alt="Aerial view of a highway with 18-wheel tractor-trailers transporting shipping containers"
          />
        </template>
        <p class="title">Help transform EV fleet possibilities into realities</p>
        <p>
          Local engagement is needed to provide access to depots or other locations for charging stations, vehicle
          procurement, and funding for ongoing operational expenses.
        </p>
        <p><strong>Electrify America is seeking collaborators in the following areas:</strong></p>
        <DecorativeList>
          <li><p>Transit depot charging</p></li>
          <li><p>Enroute transit charging</p></li>
          <li>
            <p>Semi-public & private medium- and heavy-duty depots</p>
          </li>
        </DecorativeList>
      </Card>
    </div>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import Card from '@/components/Card/Card.vue';
import DecorativeList from '@/components/DecorativeList/DecorativeList.vue';

export default {
  name: 'GreenCity',
  metaInfo: {
    title: 'Green City | Electrify America',
    meta: [
      {
        name: 'description',
        content: `Electrify America's Green City.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/green-city/' }],
  },
  components: { Hero, Card, DecorativeList },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style scoped lang="scss">
.form-title {
  padding-top: 100px;
  h2 {
    margin-bottom: 20px;
  }
}
.title {
  font-size: 24px;
  margin-bottom: 0;
}
</style>
